import type { IRequestOptions, ApiRequestOptions as TApiRequestOptions } from 'o365.pwa.declaration.sw.apiRequestOptions.ApiRequestOptions.d.ts';
import type { TruncateIndexDBObjectStoreMode } from "o365.pwa.types.ts";
import type { Request } from 'o365.pwa.declaration.sw.ServiceWorkerGlobalScope.d.ts';

declare module 'o365.pwa.declaration.sw.apiRequestOptions.ApiPwaOfflineSyncRequestOptions.d.ts' {
    interface IApiPwaOfflineSyncBaseOptions {
        dataObjectId: string;
        personID?: string;
        rowCountTimeout?: number;
        requestGuid: string;
        appId: string;
        dataSourceId: string;
        viewName: string;
        fields: any;
        distinctRows: boolean;
        filterString: string;
        whereClause: string;
        masterDetailString: string;
        shouldGenerateOfflineData: boolean;
        offlineDataProcName: string;
        objectStoreIdOverride: string;
        truncateMode: TruncateIndexDBObjectStoreMode;
        fileStore?: boolean;
        expandView?: boolean;
        definitionProc?: string;
        definitionProcParameters?: any;
    }

    interface IGenerateOfflineDataOptions extends IApiPwaOfflineSyncBaseOptions {
        originalViewName: string;
        offlineDataType: string;
        deviceRef?: string;
        failOnNoRecords?: boolean;
    }

    export function isGenerateOfflineDataOptions(obj: any): obj is IGenerateOfflineDataOptions;

    export class ApiPwaOfflineSyncOptions implements IRequestOptions {
        readonly dataObjectId: string;
        readonly personID?: string;
        readonly rowCountTimeout?: number;
        readonly requestGuid: string;
        readonly appId: string;
        readonly dataSourceId: string;
        readonly shouldGenerateOfflineData: boolean;
        readonly viewName: string;
        readonly fields: any;
        readonly distinctRows: boolean;
        readonly filterString: string;
        readonly whereClause: string;
        readonly masterDetailString: string;
        readonly originalViewName?: string;
        readonly offlineDataType?: string;
        readonly offlineDataProcName?: string;
        readonly appIdOverride?: string;
        readonly databaseIdOverride?: string;
        readonly objectStoreIdOverride?: string;
        readonly truncateMode: TruncateIndexDBObjectStoreMode;
        readonly deviceRef?: string;
        readonly fileStore?: boolean;
        readonly expandView?: boolean;
        readonly definitionProc?: string;
        readonly definitionProcParameters?: any;
        readonly failOnNoRecords?: boolean;

        constructor(options: IApiPwaOfflineSyncBaseOptions | IGenerateOfflineDataOptions);

        static fromRequest(request: Request): Promise<TApiRequestOptions<ApiPwaOfflineSyncOptions>>;
    }
}
